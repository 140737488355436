<div class="key-people-each-card">
  @if (showAllPeople) {
    <div class="show-all-icons">
      @for (item of [1,2,3,4]; track $index) {
        <i class="fad fa-user-circle" [ngStyle]="getRandomUserIconColours($index+1)"></i>
      }
    </div>
    <span class="text-grey-600 mb-2">Total {{totalCount}} people</span>
    <button class="rounded-btn-base rounded-btn-vivid btn-p-md" (click)="triggerKeyPeopleModalOpen()">
      See all team</button>
  }
  @else {
    
    <div class="user-icon-and-pie-container">
      <i class="fad fa-user-circle" [ngStyle]="getRandomUserIconColours()"></i>

      @if (person.natures_of_control?.length) {         
          <div class="control-pie-container" [matTooltip]="getControlNatureTooltip(person.natures_of_control)" matTooltipClass="pie-tooltip">
            <mat-progress-spinner [diameter]="40" color="primary" [strokeWidth]="6" [mode]="'determinate'" [value]=" getControlNaturePercentage()">
            </mat-progress-spinner>
            <span class="control-weighting">{{person.control_weighting}}</span>            
          </div>

        }
    </div>
  <h6>
    {{person.forename}}
    {{person?.middle_name}}
    {{person.surname}}
    @if (person.nationality_codes?.length) {
      @for (code of person.nationality_codes; track $index) {
        <small class="mr-1" [ngClass]="'flag-icon flag-icon-' + code | lowercase">
        </small>
      }
    }
  </h6>
  <div class="role-contact-container">
    @if (person.officer_roles?.length) {
      @for (role of person.officer_roles; track $index) {
        <span class="badge-light-grey">{{role}}</span>
      }
    }
    @if (person.website_occupation) {
    <span class="badge-light-grey">{{person.website_occupation}}</span>
    }

    @if (person.occupation) {
    <span class="badge-light-grey">{{person.occupation}}</span>
    }

    @if (person.linkedin_url) {
    <a [href]="person.linkedin_url" target="_blank">
      <i class="fab fa-linkedin-in linkedIn-base-icon"
        [ngClass]="person.is_direct_linkedin_url ? 'linkedIn-icon-direct':'linkedIn-icon-search'"></i>
    </a>
    }

    @if (person.information_url) {
    <a [href]="person.information_url" target="_blank">
      <i class="fal fa-globe"></i>
    </a>
    }
  </div>

  <div class="appointment-container" [ngStyle]="{'margin-top': !person.appointed_on && !person.tenure && !person.age ? '1rem' : '0.5rem'}">
    @if (person.appointed_on) {
    <span><i class="fal fa-clock mr-1"></i>Appointed {{person.appointed_on}}</span>
    }

    <div class="age-container">
      @if (person.tenure) {
      <span><i class="fal fa-briefcase mr-1"></i>Tenure: {{person.tenure}}</span>
      }

      @if (person.age) {
      <span><i class="fal fa-hourglass mr-1"></i>Age: {{person.age}}</span>
      }
    </div>

    @if (!person.appointed_on && !person.tenure && !person.age) {
      <div class="empty-container"></div>
    }

  </div>
}
</div>