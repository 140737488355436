import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { of } from 'rxjs';

@Injectable()
export class ZintGrowSubscriptionsService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    loadingNotifications: false,
    loadingAIPitch: false,
    loadingSuggestions: false,
    loadingTrackedCompanies: false,
  };

  getUserTypeCustomColumns(): Observable<any> {
    return this.http
      .post(
        baseUrl + '/get_user_type_custom_columns/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getUserTypeCustomColumns', []))
      );
  }

  updateUserColumnCompany(
    userColumnId,
    companyId,
    teamMemberId
  ): Observable<any> {
    const params = new HttpParams()
      .set('user_column', userColumnId)
      .set('uk_company', companyId)
      .set('value', teamMemberId);
    return this.http
      .post(
        baseUrl + '/user_option_user_column_entries/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(this.pubMethods.handleError('updateUserColumnCompany', []))
      );
  }

  getUserColumnCompanies(
    userColumnId,
    pageNumber: number,
    superUser?: boolean
  ): Observable<any> {
    this.isLoading['loadingTrackedCompanies'] = true;
    const url =
      baseUrl +
      `/user_option_user_column_entries/?page=${
        pageNumber + 1
      }&usercolumn=${userColumnId}`;
    return this.http
      .get(url, this.auth.helperGetVerifiedGetParams(userColumnId))
      .pipe(
        tap(_ => (this.isLoading['loadingTrackedCompanies'] = false)),
        catchError(error => {
          if (superUser) {
            this.pubMethods.handleError(
              'getUserColumnCompany',
              [],
              this.isLoading
            )(error);
            return;
          } else {
            return of(error);
          }
        })
      );
  }

  deleteUserColumnCompany(trackingObjId): Observable<any> {
    return this.http
      .delete(
        baseUrl + `/user_option_user_column_entries/${trackingObjId}/`,
        this.auth.helperGetVerifiedDeleteDict(trackingObjId)
      )
      .pipe(
        tap(() => {}),
        catchError(this.pubMethods.handleError('deleteUserColumnCompany', []))
      );
  }

  getEventSourceTypes(): Observable<any> {
    return this.http
      .get(
        baseUrl + '/event_source_types/',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('getEventSourceTypes', []))
      );
  }

  listRuleSets(): Observable<any> {
    return this.http
      .get(baseUrl + '/rule_sets/', this.auth.helperGetVerifiedPostDict())
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('listRuleSets', []))
      );
  }

  createRuleSet(ruleSet): Observable<any> {
    return this.http
      .post(
        baseUrl + '/rule_set/',
        ruleSet,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('createRuleSet', []))
      );
  }

  updateRuleSet(ruleSet): Observable<any> {
    this.isLoading['loadingUpdateRuleSet'] = true;
    return this.http
      .put(
        baseUrl + '/rule_set/',
        ruleSet,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingUpdateRuleSet'] = false)),
        catchError(
          this.pubMethods.handleError('updateRuleSet', [], this.isLoading)
        )
      );
  }

  deleteRuleSet(ruleSetId): Observable<any> {
    return this.http
      .delete(
        baseUrl + '/rule_set/' + ruleSetId + '/',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => _),
        catchError(this.pubMethods.handleError('deleteRuleSet', []))
      );
  }

  requestSimilarTerms(term: string, eventCategory: string): Observable<any> {
    this.isLoading['loadingSuggestions'] = true;
    let params = new HttpParams()
      .set('searchTerm', term)
      .set('index', eventCategory);
    return this.http
      .post(
        baseUrl + '/generate_similar_terms/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSuggestions'] = false)),
        catchError(
          this.pubMethods.handleError('requestSimilarTerms', [], this.isLoading)
        )
      );
  }
}
