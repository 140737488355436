<div *ngIf="pubMethods.hasData(tableData.rows)" class="backend-table-container" [style.max-height]="tableHeight">
  <table class="table backend-table" [ngClass]="{'table-layout-fixed': fixedTableLayout}">
    <thead>
      <tr class="header-row">
        <th scope="col" class="sticky-col">{{tableName}}</th>
        @for (colObj of tableData.columns; track $index) {
          <th scope="col" [ngClass]="{'text-center': columnHeaderCentered}">
            @if (colObj.url_to_pdf_file) {
            <a [href]="colObj.url_to_pdf_file" target="_blank" class="text-reset" matTooltip="View Accounts">
              {{colObj.column_name}}
              <i class="fal fa-external-link text-primary"></i>
            </a>
          }
          @else {
            {{colObj.column_name}}
          }
          </th>
        }
        <th *ngIf="showTrend" scope="col">Trend</th>
      </tr>
    </thead>

    <tbody>
      @for (rowObj of tableData.rows; track $index) {
      <tr>
        <th scope="row" class="sticky-col" [ngClass]="{'text-break': breakRowHeaders}">{{rowObj.name}}</th>
        @for (colObj of tableData.columns; track $index) {
        <td>
          @if (rowObj.data[colObj.column_name]) {
            @if (rowObj.type === 'value_and_percentage') {
              <span class="d-flex justify-content-center flex-gap-05" [innerHTML]="rowObj.data[colObj.column_name] | companyDataPipe: rowObj.type"></span>
            }
            @else {
            {{ rowObj.data[colObj.column_name] | companyDataPipe: pubMethods.getFormattingType(rowObj.type) }}
            }
          }
          @else {
          &#65343;&#65343;&#65343;&#65343;
          }
        </td>

        }
        @if (showTrend) {
        <td>
          <sparkline [data]="trendData[rowObj.name]" [formatType]="rowObj.type" [width]="90" [height]="22" [lineColor]="'var(--zint-grey-500)'">
          </sparkline>
        </td>
        }
      </tr>
      }
    </tbody>
  </table>
</div>