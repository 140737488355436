import { Component, Input, ViewChild } from '@angular/core';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexStroke,
  ApexPlotOptions,
  ApexTheme,
  ApexTooltip,
  ChartComponent,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { CompanyDataPipe } from '../../../pipes/company-data.pipe';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: any;
  stroke: ApexStroke;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  theme: ApexTheme;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  colors: any;
  title: ApexTitleSubtitle;
};

export type DonutDataInput = {
  series: ApexNonAxisChartSeries;
  legendLabels: string[];
  width: number;
  alwaysShowDataLabels: boolean;
  colours: string[];
  theme: ApexTheme;
  totalLabel: string;
  chartTitle: string;
};

@Component({
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.css',
  providers: [CompanyDataPipe],
})
export class DonutChartComponent {
  @Input() donutData: Partial<DonutDataInput> = {};
  chartConfig: Partial<ChartOptions> = {};
  dataLabelVisibility: boolean[] = [];
  @ViewChild('donutChart') donutChart: ChartComponent;

  mutedColours = [
    '#4A2673',
    '#5A79B9',
    '#5A91A8',
    '#00A1E0',
    '#B0BBDD',
    '#6D6381',
    '#48659D',
    '#8498BD',
    '#9D7AC4',
    '#8A61B9',
  ];

  constructor(private companyDataPipe: CompanyDataPipe) {}

  ngOnInit() {
    //init this array with all false
    this.dataLabelVisibility = new Array(this.donutData.series.length).fill(
      false
    );

    this.buildDonutChart();
  }

  buildDonutChart() {
    const donutSelf = this;
    this.chartConfig = {
      title: {
        text: donutSelf.donutData.chartTitle,
        align: 'center',
        margin: 0,
        offsetX: 0,
        offsetY: -1,
        floating: false,
        style: {
          fontSize: '13px',
          fontWeight: 'normal',
          color: '#000a36',
        },
      },
      series: donutSelf.donutData.series,
      chart: {
        width: donutSelf.donutData?.width || donutSelf.getChartWidth(),
        type: 'donut',
        events: {
          legendClick: function (chartContext, seriesIndex) {
            donutSelf.toggleDataLabel(seriesIndex);
          },
        },
      },
      stroke: {
        width: 0,
      },
      legend: {
        show: true,
        position: donutSelf.getLegendLabelPosition(),
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 20,
                formatter: function (label: string) {
                  //long labels are abbreviated
                  if (label.length > 20) {
                    return donutSelf.companyDataPipe.transform(
                      label,
                      'company logo',
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      true
                    );
                  } else {
                    return label;
                  }
                },
              },
              value: {
                offsetY: donutSelf.donutData.totalLabel ? -20 : 0, // only offset if totalLabel
                fontSize: '16px',
                formatter: function (val) {
                  // large values are rounded
                  const numberValue = Number(val);
                  return numberValue > 999
                    ? donutSelf.companyDataPipe.transform(
                        numberValue,
                        'round numbers'
                      )
                    : numberValue;
                },
              },
              total: {
                showAlways: true,
                show: true,
                label: donutSelf.donutData.totalLabel,
                fontSize: '12px',
                formatter: function (w) {
                  const series = w?.config?.series;
                  return series.length;
                },
              },
            },
          },
        },
      },
      labels: donutSelf.donutData.legendLabels,
      dataLabels: {
        enabled: donutSelf.donutData.alwaysShowDataLabels ?? true,
        style: {
          fontSize: '11px',
        },
        formatter: function (val, opts) {
          if (donutSelf.donutData.alwaysShowDataLabels === true) {
            return Number(val).toFixed(1) + '%';
          }
          return '';
        },
      },
      colors: donutSelf.donutData.colours || donutSelf.mutedColours,
      theme: donutSelf.donutData.theme, // colors or theme (colors > theme)
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
      },
      responsive: [
        {
          breakpoint: 700,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  toggleDataLabel(seriesIndex: number) {
    /**
     * alwaysShowDataLabels true -> won't toggle
     * alwaysShowDataLabels false -> won't toggle
     * alwaysShowDataLabels false means NEVER show them
     */

    if (this.donutData.hasOwnProperty('alwaysShowDataLabels')) return;

    this.dataLabelVisibility[seriesIndex] =
      !this.dataLabelVisibility[seriesIndex];
    this.updateChartDataLabels();

    // Hide data label after 2 secs
    setTimeout(() => {
      this.dataLabelVisibility[seriesIndex] = false;
      this.updateChartDataLabels();
    }, 2200);
  }

  updateChartDataLabels() {
    this.donutChart.updateOptions({
      dataLabels: {
        formatter: (val, opts) => {
          return this.dataLabelVisibility[opts.seriesIndex]
            ? Number(val).toFixed(1) + '%'
            : '';
        },
      },
    });
  }

  getLegendLabelPosition(): 'right' | 'bottom' {
    const hasLongString = this.donutData.legendLabels.some(
      label => label.length > 16
    );
    return hasLongString ? 'bottom' : 'right';
  }

  getChartWidth(): number {
    // long series = bigger width
    return this.donutData.series?.length > 13 ? 550 : 400;
  }
}
