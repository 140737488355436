import { Injectable, signal } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable()
export class DataSharingService {
  private dataSourceTagToDelete = new BehaviorSubject<any>('');
  currentSelectedTagIdToDelete = this.dataSourceTagToDelete.asObservable();

  private dataSourceSelectedCurrentCompanyIds = new BehaviorSubject<any>([]);
  currentSelectedCompanyIds =
    this.dataSourceSelectedCurrentCompanyIds.asObservable();

  private dataSourceSelectedCurrentSearchParams = new BehaviorSubject<any>(
    new HttpParams()
  );
  currentSelectedSearchParams =
    this.dataSourceSelectedCurrentSearchParams.asObservable();

  private zgResultCountSource = new BehaviorSubject<number>(0);
  currentZgResultCount: Observable<number> =
    this.zgResultCountSource.asObservable();

  companyTagsUpdated = signal(false);

  /**
   * profile data related caches
   */
  cachedCorporateStructureMap = new Map<string, Observable<any>>();
  cachedImmediateClustersMap = new Map<string, Observable<any>>();
  cachedFullClustersMap = new Map<string, Observable<any>>();
  cachedSupplierDataMap = new Map<string, Observable<any>>();
  cachedDetailedImportsExportsDataMap = new Map<string, Observable<any>>();
  cachedCrossSellDataMap = new Map<string, Observable<any>>();

  //-- caches above should also be listed here for clearing --
  private cacheMaps: Map<string, Observable<any>>[] = [
    this.cachedCorporateStructureMap,
    this.cachedImmediateClustersMap,
    this.cachedFullClustersMap,
    this.cachedSupplierDataMap,
    this.cachedDetailedImportsExportsDataMap,
    this.cachedCrossSellDataMap,
  ];
  // --------------- //

  constructor() {}

  changeDataSourceCurrentCompanyIds(inputData: any) {
    this.dataSourceSelectedCurrentCompanyIds.next(inputData);
    // We don't actually want to clear these HTTP Params as the query is required for downloading to add ES Colums
    // this.dataSourceSelectedCurrentSearchParams.next(new HttpParams());
    this.dataSourceTagToDelete.next('');
  }

  updateJustCurrentSearchParams(searchParams: any) {
    this.dataSourceSelectedCurrentSearchParams.next(searchParams);
  }

  changeDataSourceCurrentSearchParams(searchParams: any) {
    this.dataSourceSelectedCurrentSearchParams.next(searchParams);
    this.dataSourceSelectedCurrentCompanyIds.next([]);
    this.dataSourceTagToDelete.next('');
  }

  changeDataSourceTagIdToDelete(tagIdToDelete: any) {
    this.dataSourceTagToDelete.next(tagIdToDelete);
  }

  clearTagToDelete() {
    this.dataSourceTagToDelete.next('');
  }

  updateZgResultCount(count: number) {
    this.zgResultCountSource.next(count);
  }

  clearProfileDataCaches(): void {
    this.cacheMaps.forEach(map => map.clear());
  }
}
